import PropTypes from 'prop-types';

import BlogArticlesList from 'components/Blog/ArticlesList';
import BlogMainArticles from 'components/Blog/MainArticles';
import BlogSidebar from 'components/Blog/Sidebar';
import Layout from 'components/Layout';

import { sendEvent } from 'lib/gtag';

import BlogHead from './Head';
import BlogHeading from './Heading';
import BlogSchema from './Schema';

const GA_EVENT_CATEGORY = 'blog_home';

const onLoadMoreArticles = () =>
  sendEvent({ action: 'see_more_posts', category: GA_EVENT_CATEGORY });

const onMainArticleClick = ({ categoryName }) =>
  sendEvent({
    action: 'highlight_filter_category',
    category: GA_EVENT_CATEGORY,
    data: categoryName,
  });

const BlogPage = ({
  blogCategories: categories,
  mainArticles,
  reviews = { articles: [] },
  serverArticlesPage,
  sidebarFeaturedOffers = [],
}) => (
  <>
    <BlogHead />
    <BlogSchema />
    <Layout
      headingComponent={
        <BlogHeading
          categories={categories}
          className="mx-4 lg:mx-0"
          gaEventCategory={GA_EVENT_CATEGORY}
        />
      }
      withGridLayout={false}
      maxWidthOnMobileDevices
    >
      <BlogMainArticles
        articles={mainArticles}
        onArticleClick={onMainArticleClick}
      />
      <div className="mb-10 grid grid-flow-row grid-cols-4 gap-x-4 md:grid-cols-12">
        <section className="col-span-4 flex flex-col flex-nowrap md:col-span-12 xl:col-span-9">
          <BlogArticlesList
            initialArticlesPage={serverArticlesPage}
            onLoadMore={onLoadMoreArticles}
          />
        </section>
        <BlogSidebar
          featuredOffers={sidebarFeaturedOffers}
          reviews={reviews.articles}
        />
      </div>
    </Layout>
  </>
);

BlogPage.propTypes = {
  blogCategories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      categoryName: PropTypes.string.isRequired,
      categorySlug: PropTypes.string.isRequired,
      categoryIcon: PropTypes.string.isRequired,
    })
  ).isRequired,
  reviews: PropTypes.shape({
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        articleDescription: PropTypes.string.isRequired,
        articleId: PropTypes.number.isRequired,
        articleImage: PropTypes.string.isRequired,
        articlePublished: PropTypes.string.isRequired,
        articleModifiedDate: PropTypes.string.isRequired,
        articleSlug: PropTypes.string.isRequired,
        articleThumbnail: PropTypes.string.isRequired,
        articleTitle: PropTypes.string.isRequired,
        articleTags: PropTypes.arrayOf(
          PropTypes.shape({
            tagId: PropTypes.string.isRequired,
            tagName: PropTypes.string.isRequired,
            tagSlug: PropTypes.string.isRequired,
          })
        ),
        categoryName: PropTypes.string.isRequired,
        categorySlug: PropTypes.string.isRequired,
        commentCount: PropTypes.number.isRequired,
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        userName: PropTypes.string.isRequired,
        userPhoto: PropTypes.string.isRequired,
        userUsername: PropTypes.string.isRequired,
      })
    ),
  }),
  sidebarFeaturedOffers: PropTypes.arrayOf(
    PropTypes.shape({
      offerId: PropTypes.number.isRequired,
      offerTitle: PropTypes.string.isRequired,
      offerPrice: PropTypes.number.isRequired,
      offerOldPrice: PropTypes.number,
      offerPriceType: PropTypes.string.isRequired,
      offerPhoto: PropTypes.string.isRequired,
      offerSlug: PropTypes.string.isRequired,
    })
  ),
  mainArticles: PropTypes.arrayOf(
    PropTypes.shape({
      articleDescription: PropTypes.string.isRequired,
      articleId: PropTypes.number.isRequired,
      articleImage: PropTypes.string.isRequired,
      articlePublished: PropTypes.string.isRequired,
      articleModifiedDate: PropTypes.string.isRequired,
      articleSlug: PropTypes.string.isRequired,
      articleThumbnail: PropTypes.string.isRequired,
      articleTitle: PropTypes.string.isRequired,
      categoryName: PropTypes.string.isRequired,
      categorySlug: PropTypes.string.isRequired,
      commentCount: PropTypes.number.isRequired,
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
      articleTags: PropTypes.arrayOf(
        PropTypes.shape({
          tagId: PropTypes.string.isRequired,
          tagName: PropTypes.string.isRequired,
          tagSlug: PropTypes.string.isRequired,
        })
      ),
      userName: PropTypes.string.isRequired,
      userPhoto: PropTypes.string.isRequired,
      userUsername: PropTypes.string.isRequired,
    })
  ).isRequired,
  serverArticlesPage: PropTypes.shape({
    after: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sort: PropTypes.string.isRequired,
    before: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        articleDescription: PropTypes.string.isRequired,
        articleId: PropTypes.number.isRequired,
        articleImage: PropTypes.string.isRequired,
        articlePublished: PropTypes.string.isRequired,
        articleModifiedDate: PropTypes.string.isRequired,
        articleSlug: PropTypes.string.isRequired,
        articleThumbnail: PropTypes.string.isRequired,
        articleTitle: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired,
        categorySlug: PropTypes.string.isRequired,
        commentCount: PropTypes.number.isRequired,
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        articleTags: PropTypes.arrayOf(
          PropTypes.shape({
            tagId: PropTypes.string.isRequired,
            tagName: PropTypes.string.isRequired,
            tagSlug: PropTypes.string.isRequired,
          })
        ),
        userName: PropTypes.string.isRequired,
        userPhoto: PropTypes.string.isRequired,
        userUsername: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default BlogPage;
